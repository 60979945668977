/*
 * Copyright (C)  Online-Go.com
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as
 * published by the Free Software Foundation, either version 3 of the
 * License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program.  If not, see <http://www.gnu.org/licenses/>.
 */
.AchievementList {
}

.AchievementEntry {
    display: flex;
    padding-left: 2rem;

    .icon {
        flex-grow: 0;
        flex-shrink: 0;
        display: inline-block;
        width: 5rem;
        height: 5rem;
        background-size: cover;
    }

    .achievement-info {
        display: flex;
        flex-direction: column;
        margin-left: 1rem;

        .title {
            font-weight: bold;
        }

        .description {
            font-size: smaller;
        }
    }

    &.wdc2021, &.wsc2022, &.wsc2023, &.wsc2024 {
        .icon {
            background-image: url('https://cdn.online-go.com/achievements/wdc2021.png');
        }
    }
    
    &.wsc2025 {
        .icon {
            background-image: url('https://cdn.online-go.com/achievements/wsc2025.png');
        }
    }

    &.wsc2025-grand-slam {
        .icon {
            background-image: url('https://cdn.online-go.com/achievements/wsc2025-grand-slam.png');
        }
    }
    
}
